// import ConfigProvider from 'antd/es/config-provider/index.js'

import ConfigProvider from 'antd/es/config-provider'
import trTR from 'antd/es/locale/tr_TR.js'
import { antdTheme } from '~/styles/antd-theme'

export const AntdProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <ConfigProvider locale={trTR} theme={antdTheme}>
      {children}
    </ConfigProvider>
  )
}
