// https://github.com/ant-design/ant-design-examples/blob/main/examples/with-nextjs-extract-style/theme/index.tsx

import type { ThemeConfig } from 'antd/es/config-provider'
import React from 'react'

// import { ConfigProvider, ThemeConfig } from 'antd/es/config-provider'
// import ConfigProvider, { ThemeConfig } from 'antd/es/config-provider'
// import trTR from 'antd/es/locale/tr_TR'

const testGreenColor = '#52c41a'
const testRedColor = '#ff0000'

export const antdTheme: ThemeConfig = {
  token: {
    // colorPrimary: '#52c41a',
    // borderRadius: 16,
    // colorPrimary: '#dd6b20',
    // colorLink: '#dd6b20',
    fontFamily:
      'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: 14,
  },
}
